import { useState, useEffect, useMemo } from 'react';
import useAuthContext from './useAuthContext';
import { ROLE_ID } from 'src/pages/Admin/const';
import { PATH } from 'src/services/Path';
import { METHODS } from 'http';
import { REQUEST_METHOD } from 'src/utils/constants/enums';
import { ApiRequest } from 'src/api';

export const ScreenType = {
  project: 'project',
  sub_project: 'sub_project',
  site_usage: 'site_usage',
  materials: 'materials',
  shipping: 'shipping',
  machines: 'machines',
  travelling: 'travelling',
};

export const CREATE_ACTION = 'add';
export const EDIT_ACTION = 'modify';
export const DELETE_ACTION = 'delete';
export type ACTION = typeof CREATE_ACTION | typeof EDIT_ACTION | typeof DELETE_ACTION;
export type ScreenTypeKey = keyof typeof ScreenType;
export type ScreenListType = (typeof ScreenType)[ScreenTypeKey];

export type PermissionActionType = {
  project: string[];
  sub_project: string[];
  site_usage: string[];
  materials: string[];
  shipping: string[];
  machines: string[];
  travelling: string[];
};

function usePermissionAction(ScreenType?: ScreenListType) {
  const auth = useAuthContext();
  const [permissionAction, setPermissionAction] = useState<PermissionActionType>({
    project: [],
    sub_project: [],
    site_usage: [],
    materials: [],
    shipping: [],
    machines: [],
    travelling: [],
  });

  useEffect(() => {
    const fetchPermissionAction = async () => {
      const response = await ApiRequest(`${PATH.ECO_PERMISSION_ACTION}/${auth.userInfo?.user.id}`, REQUEST_METHOD.GET, true);
      if (response?.data) {
        setPermissionAction({ ...response.data });
      }
    };

    fetchPermissionAction();
  }, []);

  const allowAction = (action: ACTION) => {
    if (ROLE_ID.ADMIN === auth.userInfo?.user?.role_id) {
      return true;
    }
    if (permissionAction && ScreenType) {
      return permissionAction[ScreenType as ScreenTypeKey].includes(action);
    }
    return false;
  };

  const permissionActionData = useMemo(() => permissionAction, [permissionAction]);

  return {
    allowAction,
    permissionActionData,
  };
}

export default usePermissionAction;
