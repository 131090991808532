import axios, { AxiosError, AxiosResponse } from 'axios';
import { ORGANIZATIONAL, REQUEST_METHOD } from '../utils/constants/enums';
import { IResponse, Obj } from '../interfaces/common/common';
import { KEY } from '../utils/configs/app';
import { authHelper } from '../utils/helpers';
import { AUTHENTICATION_FAIL_CODE, NOT_ALLOW_CODE, NOT_AUTHORIZED_CODE } from 'src/utils/constants/http';
import _ from 'lodash';

export const configAxios = (
  url: string,
  method: REQUEST_METHOD,
  requireToken?: boolean,
  data?: Obj,
  headerConfig?: Obj,
  responseType?: string,
) => {
  let config: any = {
    url,
    method,
    params: method === REQUEST_METHOD.GET || method === REQUEST_METHOD.DELETE ? data : undefined,
    baseURL: KEY.BASE_URL,
    data,
    responseType: responseType,
  };
  if (requireToken) {
    config = {
      ...config,
      headers: {
        'Content-Type': 'application/json',
        Authorization: authHelper.accessToken(),
        ...headerConfig,
      },
    };
  }
  return config;
};
export async function ApiRequest(
  url: string,
  method: REQUEST_METHOD,
  requireToken?: boolean,
  data?: any,
  headerConfig?: any,
  responseType?: any,
): Promise<IResponse | any> {
  let paramData = data;
  if (!url.includes('/upload') && !url.includes('/import')) {
    paramData = _.cloneDeep(data ?? {});
    paramData.eco_organization = ORGANIZATIONAL.ECO_BUILD;
  }
  const config = configAxios(url, method, requireToken, paramData, headerConfig, responseType);

  try {
    const response: AxiosResponse = await axios(config);
    return response.data;
  } catch (error: any) {
    let status = error.response?.status ?? 500;
    let message = error.response?.data?.error?.message ? error.response?.data?.error?.message : error.message;
    let details = error.response?.data?.error?.details ? error.response?.data?.error?.details : error.details;
    if (status === AUTHENTICATION_FAIL_CODE || status === NOT_ALLOW_CODE) {
      return {
        data: null,
        status: status,
        message: message,
      };
    }

    if (status === NOT_AUTHORIZED_CODE) {
      return {
        isError: true,
        data: null,
        status: status,
        message: error.response?.data?.message,
      };
    }

    return {
      isError: true,
      data: null,
      status: status,
      message: message,
      details: details,
    } as IResponse;
  }
}

export default axios.create({
  baseURL: KEY.BASE_URL,
});

export const axiosPrivate = axios.create({
  baseURL: KEY.BASE_URL,
  headers: { 'Content-Type': 'application/json' },
});
