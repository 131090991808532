export enum REQUEST_METHOD {
  GET = 'get',
  POST = 'post',
  PUT = 'put',
  DELETE = 'delete',
}

export enum PAGE {
  PAGE_SIZE_10 = 10,
  PAGE_SIZE_15 = 15,
  PAGE_SIZE_20 = 20,
  PAGE_SIZE_25 = 25,
  PAGE_SIZE_30 = 30,
}
export const enum ROLE {
  ADMIN = 7,
  USER = 8,
  PROJECT_MANAGER = 6,
}

export const ROLE_PERMISSION = {
  NEED_CHECK_VISIBILITY: [ROLE.USER, ROLE.PROJECT_MANAGER],
  NEED_CHECK_MODIFY: [ROLE.USER, ROLE.PROJECT_MANAGER],
  NEED_APPROVED: [ROLE.USER, ROLE.PROJECT_MANAGER],
  CAN_APPROVED: [ROLE.ADMIN],
  CAN_SETTING_USER: [ROLE.ADMIN, ROLE.PROJECT_MANAGER],
  CAN_ACTIVE_TAB: [ROLE.ADMIN],
  CAN_MODIFY: [ROLE.ADMIN],
  CAN_SHARE: [ROLE.ADMIN, ROLE.PROJECT_MANAGER],
};

export const DATE_PATTERN = {
  YYYY_MM_DD: 'YYYY/MM/DD',
  YYYY_MM_DD_HH_mm_ss: 'YYYY/MM/DD HH:mm:ss',
  DD_MM_YYYY: 'DD/MM/YYYY',
  YYYY: 'YYYY',
  MM_YYYY: 'MM/YYYY',
  ISO: 'YYYY-MM-DDTHH:mm:ss.SSS[Z]',
};

export const NOTIFY_TYPE = {
  SUCCESS: 'success',
  ERROR: 'error',
  INFO: 'info',
  WARNING: 'warning',
};

export const PAGINATION = {
  DEFAULT_PAGE: 1,
  DEFAULT_PAGE_SIZE: 10,
  PAGE_SIZE_OPTION: [10, 15, 20, 25, 30],
  DEFAULT_TOTAL_PAGE: 1,
};

export enum IMPACTS_TYPE {
  WASTE = 'Waste',
  WATER_DISPOSAL = 'Water disposal (non domestic activities)',
  WATER_WITHDRAWAL = 'Water withdrawal',
  ENERGY_USE = 'Energy use',
  OIL_AND_CHEMICALS = 'Oil & chemical spills',
  OIL_AND_CHEMICALS_OTHER_CHEMICALS = 'Other chemicals',
  UNIT_M3 = 'm3',
  UNIT_KL = 'kL',
  UNIT_CO2EQ = 'kg CO2e',
  UNIT_MWH = 'MWh',
  KWH = 'kWh',
}
export const DEFAULT_LIMIT_SIZE = 100;
export const MONTH = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

export enum TYPE_FILE {
  IMAGE_JPG = 'jpg',
  IMAGE_JPEG = 'jpeg',
  PDF = 'pdf',
  DOC = 'doc',
  DOCX = 'docx',
  XLS = 'xls',
  XLS_EXCEL = 'xlsx',
  MS_WORD = 'doc',
  DOCUMENT = 'docx',
  XLSX = 'xlsx',
  CSV = 'csv',
  ZIP = 'zip',
  XLSM = 'xlsm',
}

export const DATE_FORMAT = 'YYYY-MM-DDTHH:mm:ss.SSS[Z]';

export enum FONT_SIZE {
  FONT_SIZE_32 = 32,
  FONT_SIZE_28 = 28,
  FONT_SIZE_24 = 24,
}

export const ORGANIZATIONAL = {
  ECO_BUILD: 1,
};
