import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import React, { useState } from 'react';
import '../../styles/components/basesLayout.scss';
import home from '../../assets/icons/menu-icon/home.png';
import modified from '../../assets/icons/menu-icon/projects.png';
import result from '../../assets/icons/menu-icon/results.png';
import question from '../../assets/icons/menu-icon/help.png';
import analytics_img from '../../assets/icons/menu-icon/analytics.png';
import new_project from '../../assets//icons/createProject.svg';
import infoIcon from '../../assets/icons/menu-icon/info.png';
import useWindowSize from 'src/hooks/useWindowSize';
import usePermissionAction, { CREATE_ACTION, ScreenType } from 'src/hooks/usePermissionAction';

const BaseLayout = () => {
  const { allowAction: allowActionProject } = usePermissionAction(ScreenType.project);
  const location = useLocation();
  const navigate = useNavigate();
  const { width } = useWindowSize();
  const [collapsed, setCollapsed] = useState(false);
  const redirect = (route: string) => {
    setCollapsed(false);
    navigate(route);
  };
  return (
    <aside className={`page__basesLayout ${!location?.pathname?.includes('home') && (collapsed ? 'open-menu' : 'close-menu')}`}>
      {!location?.pathname?.includes('home') && (
        <div
          className="page__basesLayout--sidebar-navigation"
          onMouseLeave={() => setCollapsed(false)}
          onMouseEnter={() => setCollapsed(true)}
        >
          <div className="main-sidebar">
            <ul className="menu-list">
              <li
                className="item"
                onClick={() => {
                  redirect('/home');
                }}
              >
                <span className="icon">
                  <img src={home} style={width < 1400 ? (width < 1100 ? { width: '14px' } : { width: '18px' }) : { width: '24px' }} />
                </span>
                {collapsed && <span>Home</span>}
              </li>
              {allowActionProject(CREATE_ACTION) && (
                <li
                  className="item"
                  onClick={() => {
                    redirect('/projects/create');
                  }}
                >
                  <span className="icon">
                    <img
                      src={new_project}
                      style={width < 1400 ? (width < 1100 ? { width: '14px' } : { width: '18px' }) : { width: '24px' }}
                    />
                  </span>
                  {collapsed && <span>New</span>}
                </li>
              )}
              <li
                className="item"
                onClick={() => {
                  redirect('/projects');
                }}
              >
                <span className="icon">
                  <img src={modified} style={width < 1400 ? (width < 1100 ? { width: '14px' } : { width: '18px' }) : { width: '24px' }} />
                </span>
                {collapsed && <span>Projects</span>}
              </li>
              <li
                className="item"
                onClick={() => {
                  redirect('/results');
                }}
              >
                <span className="icon">
                  <img src={result} style={width < 1400 ? (width < 1100 ? { width: '14px' } : { width: '18px' }) : { width: '24px' }} />
                </span>
                {collapsed && <span>Results</span>}
              </li>
              <li
                className="item"
                onClick={() => {
                  redirect('/analytics');
                }}
              >
                <span className="icon">
                  <img
                    src={analytics_img}
                    style={width < 1400 ? (width < 1100 ? { width: '14px' } : { width: '18px' }) : { width: '24px' }}
                  />
                </span>
                {collapsed && <span>Analytics</span>}
              </li>
              {/* <li
              className="item"
              onClick={() => {
                navigate('/admins');
              }}
            >
              <span className="icon">
                <img src={setting} style={width < 1400 ? (width < 1100 ? { width: '14px' } : { width: '18px' }) : { width: '24px' }} />
              </span>
              <span>Settings</span>
            </li> */}
            </ul>
            <ul className="menu-list">
              <li
                className="item"
                onClick={() => {
                  redirect('/information');
                }}
              >
                <span className="icon">
                  <img src={infoIcon} style={width < 1400 ? (width < 1100 ? { width: '14px' } : { width: '18px' }) : { width: '24px' }} />
                </span>
                {collapsed && <span>Info</span>}
              </li>
              <li
                className="item"
                onClick={() => {
                  redirect('/helps');
                }}
              >
                <span className="icon">
                  <img src={question} style={width < 1400 ? (width < 1100 ? { width: '14px' } : { width: '18px' }) : { width: '24px' }} />
                </span>
                {collapsed && <span>Help</span>}
              </li>
            </ul>
          </div>
        </div>
      )}
      <div className="page__basesLayout--content">
        <Outlet />
      </div>
    </aside>
  );
};

export default BaseLayout;
