import dayjs from 'dayjs';
import moment from 'moment';
import { DATE_PATTERN } from '../constants/enums';
import _ from 'lodash';

export const formatDate = (date: string, format: string) => {
  return moment.utc(date).local().format(format);
};

export const dayExpires = 7 * 24 * 60 * 60 * 1000;
export const dayExpires1Minute = 60 * 1000;

export const removeFileUploadExt = (fileName: string | null | undefined) => {
  return fileName?.replace(/\.[^/.]+$/, '');
};

export const transferToShortDate = (date: string) => {
  if (_.isNull(date) || _.isUndefined(date)) return '';
  try {
    const currentMonth = dayjs(date).get('month');
    const currentYear = dayjs(date).get('year');
    return `${String(currentMonth + 1).padStart(2, '0')}/${currentYear}`;
  } catch (_error) {
    return '';
  }
};

export const transferDateToISO = (date: string) => {
  if (_.isNull(date) || _.isUndefined(date)) return '';
  try {
    const travelDateValid = transferToShortDate(date);
    return moment(travelDateValid, DATE_PATTERN.MM_YYYY).format(DATE_PATTERN.ISO).toString();
  } catch (_error) {
    return '';
  }
};
