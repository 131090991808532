export const PATH = {
  REFRESH_TOKEN: 'auth/refresh-token',
  USER: 'auth/user',
  EDIT_PROFILE: 'auth/user/edit-profile',
  ACTIVE_USER: 'auth/user/active',
  DEACTIVATE_USER: 'auth/user/deactivate',
  LOG_IN: 'auth/login',
  FORGOT_PASSWORD: 'auth/forgot-password',
  RESET_PASSWORD: 'auth/reset-password',
  CHANGE_PASSWORD: 'auth/change-password',
  CHECK_TOKEN_RESET_PASSWORD: '/auth/check-token-reset-password',
  CHECK_MICROSOFT_ACCOUNT_EXIST: '/auth/check-microsoft-account-exist',
  LOG_OUT: 'auth/logout',
  PERMISSIONS: 'auth/permissions',
  PROJECT_SEARCH: '/eco-projects',
  SUB_PROJECT_SEARCH: '/eco-sub-projects',
  TOPICS_OPTION: '/eco-topics-options',
  HELP: '/eco-helps',
  PROJECT_IMPORT: '/eco-projects/import',
  PROJECT_DATA: '/eco-projects/data-project',
  PROJECT_DUPLICATE: '/eco-projects/duplicate',
  BUSINESS_UNIT: '/eco-business-units',
  BUSINESS_LINE: '/eco-business-lines',
  GLOBAL_PRODUCT: '/eco-global-product-groups',
  PURPOSE: '/eco-purpose-of-studies',
  LOCATION: 'eco-locations',
  VALUE_PER_KG_ELECTRIC_MIX_BY_LOCATION: 'eco-locations/get-value-per-kg-electric-mix-by-locations',
  CITY: 'eco-cities',
  REGION: 'eco-regions',
  SUB_PROJECT: '/eco-sub-projects/',
  SUB_PROJECT_DETAIL_BREADCRUMB: '/eco-sub-projects/get-breadcrumb',
  SUBPROJECT_DATA: 'eco-sub-projects/data-subproject',
  SUBPROJECT_DUPLICATE: '/eco-sub-projects/duplicate',
  PROJECT_DATA_ALL: '/eco-projects/list-all',
  PROJECT_DATA_RESULT: '/eco-results',
  DOWNLOAD_CSV_DATA_RESULT: '/eco-results/download-csv-results',
  DOWNLOAD_DATA_REPORT: '/eco-results/download-report',
  HANDLE_DOWNLOAD_DATA_REPORT: '/eco-results/handle-download-report',
  PROCESS_DOWNLOAD_DATA_REPORT: '/eco-results/process-download-report',
  CITY_WITH_LOCATION: 'eco-cities/get-city-with-location',
  PROJECT_EXPORT: '/eco-projects/export-project',
  DOWNLOAD_FILE: '/eco-attach-files/download',
  PROJECT_IMPORT_REQUIRED_FILES: 'eco-projects/import-projects-includes-files',

  //Site Usage
  IMPACTS: 'eco-impactss',
  IMPACTS_TYPE: 'eco-impacts-types',
  SITE_USAGE: 'eco-site-usages',
  SITE_USAGE_IMPORT: '/eco-site-usages/import',
  SITE_USAGE_IMPORT_REQUIRED_FILES: '/eco-site-usages/import-site-usage-includes-files',

  // Material
  MATERIAL_LIST: '/eco-material-lists',
  MATERIAL_TYPE: '/eco-material-types',
  MATERIALS: '/eco-materials',
  MATERIALS_IMPORT: '/eco-materials/import',

  // Machinery
  MACHINERY: '/eco-machines',
  MACHINERY_MACHINE_TOOL_NAMES: '/eco-machinery-operations',
  MACHINERY_CATEGORIES: '/eco-machinery-operation-types',
  MACHINERY_SOURCES: '/eco-fuels',
  MACHINERY_IMPORT: '/eco-machines/import',

  // Shipping
  SHIPPINGS: '/eco-shippings',
  SHIPPING_ITEM: '/eco-shipping-items',
  SHIPPING_LEG: '/eco-shipping-legs',
  SHIPPING_TYPE: '/eco-shipping-types',
  SHIPPING_LIST: '/eco-shipping-lists',
  SHIPPINGS_IMPORT: '/eco-shippings/import',
  SHIPPINGS_IMPORT_REQUIRED_FILES: '/eco-shippings/import-shippings-includes-files',

  // Travelling
  TRAVELLING: '/eco-travellings',
  TRAVELLING_TYPE: '/eco-travelling-types',
  TRAVELLING_IMPORT: '/eco-travellings/import',

  // Data analyze
  ANALYZE: '/eco-analyze',
  ANALYZE_FILTER: '/eco-analyze/filter',

  // Data modifiers
  ECO_MODIFIERS: '/eco-modifiers',

  // Permission action
  ECO_PERMISSION_ACTION: '/eco-permission-actions',

  // Permission access subProject
  ECO_PERMISSION_SUB_PROJECT_ACCESS: '/eco-sub-project-accesses',

  // Permission access project
  ECO_PERMISSION_PROJECT_ACCESS: '/eco-project-accesses',

  // Tutorial
  ECO_TUTORIAL: '/eco-tutorials',
};
