import { AxiosResponse } from 'axios';
import { KEY } from '../configs/app';

export const accessToken = () => {
  const userInfo = localStorage.getItem(KEY.keyAuthLocal);

  if (userInfo) {
    const { jwt } = userInfo ? JSON.parse(userInfo) : null;
    if (jwt) {
      return `Bearer ${jwt}`;
    }
  }
  return '';
};

export const setAccessToken = (axiosResponse: AxiosResponse) => {
  localStorage.setItem(KEY.keyAuthLocal, JSON.stringify(axiosResponse));
};

export const isAuth = () => {
  return Boolean(accessToken());
};

export const logOut = () => {
  localStorage.setItem(KEY.keyAuthLocal, '');
  localStorage.clear();
};
