import { Spin } from 'antd';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import useStoreGlobal from 'src/hooks/useStoreGlobal';
import { ROUTES } from 'src/utils/constants/routes';

function Loading(props: any) {
  const storeGlobal = useStoreGlobal();
  const location = useLocation();
  useEffect(() => {
    if (location.pathname === ROUTES.HOME) {
      document.body.classList.add('resize-body');
    } else {
      document.body.classList.remove('resize-body');
    }
  }, [location.pathname]);

  return (
    <Spin spinning={storeGlobal.isLoading} wrapperClassName="spinning-center">
      {props.children}
    </Spin>
  );
}
export { Loading };
