import PropTypes from 'prop-types';
import './GlobalStyles.scss';

function GlobalStyles(props: any) {
  return props.children;
}

GlobalStyles.propTypes = {
  children: PropTypes.node.isRequired,
};

export default GlobalStyles;
