import { ListMessage } from 'src/utils/message/index';

export const renderMessage = (message, positionA, postionB, postionC) => {
  return positionA
    ? message.replace('{0}', positionA)
    : postionB
    ? message.replace('{1}', postionB)
    : postionC
    ? message.replace('{2}', postionC)
    : message;
};

export const renderMessageOwner = (message, arrs) => {
  if (arrs) {
    for (let i = 0; i < arrs.length; i++) {
      message = message.replace('{' + i + '}', arrs[i]);
    }
  }
  return message;
};

export const renderMessageID = (messageID, arrs) => {
  let message = ListMessage.message[messageID];
  if (arrs) {
    for (let i = 0; i < arrs.length; i++) {
      message = message.replace('{' + i + '}', arrs[i]);
    }
  }
  return message;
};
