export const ROLE_ID = {
  ADMIN: 7,
  PROJECT_MANAGER: 6,
  USER: 8,
};

export const OPTION_ACTION = [
  { label: 'Add', value: 'add', disabled: false },
  { label: 'Modify', value: 'modify', disabled: false },
  { label: 'Delete', value: 'delete', disabled: false },
];

export const DEFAULT_OPTION_ACTION = ['add', 'modify', 'delete'];
