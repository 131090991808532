export const SUCCESS_CODE = 200;
export const VALIDATION_FAIL_CODE = 400;
export const AUTHENTICATION_FAIL_CODE = 401;
export const NOT_ALLOW_CODE = 403;
export const NOT_FOUND_CODE = 404;
export const NOT_AUTHORIZED_CODE = 405;
export const ERROR_EXCEPTION_CODE = 500;
export const RESPONSE_SUCCESS = 'OK';
export const RESPONSE_ERROR = 'ERROR';
export const ERR_NETWORK = 'ERR_NETWORK';
